import React, { PropsWithChildren, ReactElement } from "react";
import { closeBtn } from "../../../../shared/icons";
import styles from "./SubmitPopup.module.scss";

interface SubmitPopupProps {
    icon: ReactElement,
    title: string;
    message: string;
    handleClose: () => void;
}

const SubmitPopup = (props: PropsWithChildren<SubmitPopupProps>): ReactElement => (
    <div className={styles.popupWrapper}>
        <div className={styles.popup}>
            <button className={styles.closeBtn} onClick={props.handleClose}>
                {closeBtn}
            </button>

            <div className={styles.messageWrapper}>
                {props.icon}
                <div>
                    <h3>{props.title}</h3>
                    <p>{props.message}</p>
                </div>
            </div>
        </div>
    </div>
);

export default SubmitPopup;