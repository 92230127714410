import React, { PropsWithChildren, ReactElement, useState, useEffect, FormEvent, useLayoutEffect, useRef } from "react";
import { graphql } from "gatsby";
import { messageIcons } from "../../shared/icons";
import { FormElement, FormElementType, } from "../../components/shared/Form/FormElementsFactory";
import SubmitPopup from "../../components/shared/Form/SubmitPopup";
import Helmet from "../../components/Helmet";
import Form from "../../components/shared/Form";
import styles from "./contact-us.module.scss";
import { ValidationService } from "../../services/ValidationService";
import DashboardApiService from "../../services/DashboardApiService";
import { useDependency } from "../../contexts/DependencyContext";
import { isEmpty } from "lodash";
import formatters from "../../templates/Account_create_page/components/Form/formatters";
import Spinner from "../../components/shared/Spinner";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";

interface ContactProps {
    data: any;
    location: any;
    navigate: any;
}

interface Popup {
    icon: ReactElement;
    title: string;
    message: string;
}

const contactMethod = [
    'email',
    'phone'
]

const Contact = (props: PropsWithChildren<ContactProps>): ReactElement => {
    const data = JSON.parse(props.data.allPrismicContactPage.edges[0].node.dataString);
    const locations = props.data.allPrismicLocations.edges.map(edge => edge.node.data.location);



    const contactFormElements = [
        {
            type: FormElementType.Select,
            name: "gym",
            placeholder: data.right_section_club_dropdown_label ? data.right_section_club_dropdown_label[0]?.text : "Please select a club",
            options: locations.map((x: any) => ({
                label: x.name,
                id: x.businessUnitCode,
                selected: false,
            })),
        },
        {
            type: FormElementType.Text,
            name: "first-name",
            placeholder: "First Name",
            validations: {
                text: true
            },
            errorMessage: "Invalid name format",
        },
        {
            type: FormElementType.Text,
            name: "last-name",
            placeholder: "Last Name",
            validations: {
                text: true
            },
            errorMessage: "Invalid name format",
        },
        {
            type: FormElementType.Select,
            name: "categories",
            placeholder: "Select a category",
            isWhite: true,
            options: data.right_contact_topic_list.map((x: any) => ({
                label: x.topic_name[0].text,
                id: x.topic_name[0].text,
                need_id: x.need_member_id,
                selected: false
            }))
        },
        {
            type: FormElementType.Text,
            name: "member-id",
            placeholder: "Member ID",
            validations: {
                number: true,
                required: true,
                optional: true
            },
            errorMessage: "Invalid member format",
        },
        {
            type: FormElementType.Email,
            name: "email",
            placeholder: data.right_section_email_input_label ? data.right_section_email_input_label[0]?.text : "Email",
            validations: {
                remote: {
                    val: "validate/email",
                    customError: "We couldn't verify your email address, please check your input"
                }
            },
            errorMessage: "Invalid email format",
        },
        {
            type: FormElementType.Tel,
            name: "phone",
            placeholder: data.right_section_phone_input_label ? data.right_section_phone_input_label[0]?.text : "Phone",
            validations: {
                tel: true,
                remote: {
                    val: "validate/phone",
                    customError: "We couldn't validate your phone number, please check your input"
                }
            },
            errorMessage: "Invalid phone format",
            formatter: "phoneNum"
        },
        {
            type: FormElementType.Textarea,
            name: "message",
            placeholder: data.right_section_message_label ? data.right_section_message_label[0]?.text : "Message",
        },
        {
            type: FormElementType.Radio,
            name: "preferred-contact-method",
            options: contactMethod.map((x: any, i: number) => ({
                label: x,
                value: x,
            })),
            groupTraining: false
        },
        {
            type: FormElementType.Checkbox,
            name: "consent",
            placeholder: data.right_section_consent_text ? data.right_section_consent_text[0]?.text : "",
        },
    ];
    const [formData, setFormData] = useState<any>({});
    const [formElements, setFormElements] = useState<FormElement[]>(contactFormElements);
    const [formErrors, setFormErrors] = useState({});
    const errorsRef = useRef({});
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
    const [popup, setPopup] = useState<Popup>(null as any);

    const validationService = useDependency(ValidationService);

    const handleInputChange = ({ target }: FormEvent): void => {
        const input = target as HTMLInputElement | HTMLTextAreaElement;

        const formElement = contactFormElements.find(fElem => fElem.name === target.name);

        let value = input.value;

        if (formElement?.formatter) {
            const formatterObj = formatters[formElement.formatter];
            const Formatter = formatterObj.class;
            let formatterInstance;

            if (formatterObj.instances[input.name]) {
                formatterInstance = formatterObj.instances[input.name];
            } else {
                formatterObj.instances[input.name] = new Formatter();
                formatterInstance = formatterObj.instances[input.name];
            }

            value = formatterInstance.format(input.value);
        }

        setFormData({
            ...formData,
            [input.name]:
                input.type === FormElementType.Checkbox
                    ? (input as HTMLInputElement).checked
                    : value,

        });
    };

    const skipValidation = (name, value) => {
        const arrCopy = [...formElements];
        const optional = arrCopy.find(e => e.name === name);
        optional.validations.skip = value;        
        optional.validations.optional = value;
        const errors = {...formErrors};

        if (value) {
            delete errors[name];
        } else {
            errors[name] = errorsRef.current[name];
        }

        setFormErrors(errors)
        setFormElements(arrCopy);
    }   

    const handleBlurValidation = async ({ target }: FormEvent): void => {
        const errors = {};

        const { name, type, validations } = formElements.find(e => e.name === target.name);

        if (validations) {
            for (const key in validations) {
                const validationValue = validations[key];
                const value = formData[name];
                let isValid = false;

                isValid = await validationService.setValidation(
                    value,
                    key,
                    validationValue,
                    name
                );

                if (!isValid) {
                    if (!errors[name]) {
                        errors[name] = [];
                    }

                    let customMsg = "";

                    if (typeof validationValue === "object") {
                        if (validationValue.customError) {
                            customMsg = validationValue.customError;
                        }
                    }

                    const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                    errors[name][0] = errMsg;
                    errorsRef.current[name] = errMsg;                
                }
            }
        }

        if (!isEmpty(errors)) {
            setFormErrors({
                ...formErrors,
                ...errors
            })
        } else {
            const _formErrors = { ...formErrors };

            delete _formErrors[name];

            setFormErrors(_formErrors);
        }
    };

    const getOptionNeedId = (value : String) => {
        const category = formElements.find(e => e.name === "categories");
        const option = category.options.find(o => o.label === value);
        return option.need_id;
    }

    const handleSelectChange = (name: string, value: string): void => {
        // TO DO: Simplify this
        if (name === "categories" && getOptionNeedId(value)) {
            const arrCopy = [...formElements];
            const optional = arrCopy.find(e => e.name === "member-id");
            optional.validations.optional = false;

            setFormElements(arrCopy);
        } else {
            const arrCopy = [...formElements];
            const optional = arrCopy.find(e => e.name === "member-id");
            optional.validations.optional = true;

            setFormElements(arrCopy);
        }

        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmission = async (e: FormEvent): Promise<any> => {
        e.persist();
        e.preventDefault();
        setIsLoad(true);

        const form = e.target as HTMLFormElement;
        const recaptchaResponse = grecaptcha.getResponse();
        const selectOptions = formElements.filter(x => x.options)[0];
        const selectedForm = selectOptions.options?.filter(option => option.selected)[0];
        const errors = {};

        setRecaptchaError(!recaptchaResponse);

        if (!recaptchaResponse) {
            setIsLoad(false)
            return
        };

        if (formData) {
            for (const formElem of formElements) {
                const { name, type, validations } = formElem;

                if (validations) {
                    if (validations.skip) break;

                    for (const key in validations) {
                        const validationValue = validations[key];
                        const value = formData[name];
                        let isValid = false;

                        if (validations.optional && !value || validations.skip) {
                            break;
                        }

                        isValid = await validationService.setValidation(
                            value,
                            key,
                            validationValue,
                            name
                        );

                        if (!isValid) {
                            if (!errors[name]) {
                                errors[name] = [];
                            }

                            
                            let customMsg = "";

                            if (typeof validationValue === "object") {
                                if (validationValue.customError) {
                                    customMsg = validationValue.customError;
                                }
                            }

                            const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                            errors[name][0] = errMsg;
                            errorsRef.current[name] = errMsg;                        }
                    }
                }
            }

            if (isEmpty(errors)) {
                const postData = {
                    name: "contact-us",
                    businessUnitCode: locations.find(l => l.name === formData.gym).businessUnitCode,
                    formData: formData,
                    recaptchaToken: recaptchaResponse
                };

                const response = await DashboardApiService.submitForm(postData);

                if (response.data.success) {
                    setIsLoad(false);
                    props.navigate("/contact-us/thank-you");
                } else {
                    setIsLoad(false);
                    setPopup({
                        icon: messageIcons.error,
                        title: "Something went wrong",
                        message: "Please try again",
                    });
                }
            } else {
                setIsLoad(false);
                setFormErrors(errors);
            }
        }
    };

    useLayoutEffect(() => {
        if (props.location && props.location.state) {
            const { club } = props.location.state;

            const elements = contactFormElements.map(element => {
                if (element.options)
                    element.options.forEach(
                        (x: any) => {
                            const selected = element.options.includes(club?.toLowerCase());

                            if (selected) setFormData({ ...formData, [element.name]: x.label });
                            return (x.selected = selected);
                        }
                    );

                return element;
            });

            setFormElements(elements);
        } else {
            setFormElements(contactFormElements);
        }
    }, [props.location.state]);

    useEffect(() => {
        if (!popup) return;
        setTimeout(() => window.location.reload(), 3000);
    }, [popup]);

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Contact")}
                description={getSeoDescription(data, "Contact Page")}
                keywords={getSeoKeywords(data, "")} 
            >
                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
            </Helmet>

            <div className={styles.sectionsWrapper}>
                <div
                    className={styles.leftSection}
                    style={{ backgroundImage: `url(${data.left_section_background_image?.url})` }}
                >
                    {data.left_section_title && (
                        <h2>{data.left_section_title[0].text}</h2>
                    )}

                    {data.left_section_text && (
                        <p>{data.left_section_text[0].text}</p>
                    )}
                </div>

                <div className={styles.rightSection}>
                    <Form
                        name="contact-form"
                        title="Contact Us"
                        data={formData}
                        elements={formElements}
                        formErrors={formErrors}
                        handleBlurValidation={handleBlurValidation}
                        recaptchaError={recaptchaError}
                        {...{
                            handleInputChange,
                            handleSelectChange,
                            handleFormSubmission,
                            skipValidation
                        }}
                    />
                </div>

                {popup && (
                    <SubmitPopup
                        icon={popup.icon}
                        title={popup.title}
                        message={popup.message}
                        handleClose={() => window.location.reload()}
                    />
                )}
            </div>

            {isLoad && <Spinner></Spinner>}
        </>
    );
};

export const query = graphql`
    query {
        allPrismicContactPage {
            edges {
                node {
                    dataString
                }
            }
        }
        allPrismicLocations {
            edges {
                node {
                    id
                    data {
                        location {
                            businessUnitCode
                            name
                            division
                        }
                    }
                }
            }
        }
    }
`;

export default Contact;
